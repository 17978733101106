
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import SporteventResult from "../../models/sportevent/SporteventResult";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import PrintSettingsService from "../../services/helper/PrintSettingsService";

@Component({})
export default class ListResultsSingleTableComponent extends Vue {
  public loading = true;
  public hide = true;

  @Prop({ required: true })
  public sporteventCompetition!: SporteventCompetition;

  @Prop({ required: true })
  public allSporteventResult!: SporteventResult[];

  @Prop({ default: false })
  public sortRankReverse!: boolean;

  @Prop({ default: false })
  public isCommonList!: boolean;

  public currentResults: SporteventResult[] = [];

  @Watch("sortRankReverse")
  onPropertyChanged(sortReverse: boolean) {
    if (sortReverse) {
      this.currentResults.sort((a, b) => {
        if (a.totalPoints < b.totalPoints) return -1;
        if (a.totalPoints > b.totalPoints) return 1;
        return 0;
      });
    } else {
      this.currentResults.sort((a, b) => {
        if (a.totalPoints < b.totalPoints) return 1;
        if (a.totalPoints > b.totalPoints) return -1;
        return 0;
      });
    }
  }

  async mounted(): Promise<void> {
    if (this.isCommonList) {
      this.currentResults = this.allSporteventResult;
    } else {
      this.currentResults = this.allSporteventResult
        .filter((r: SporteventResult) =>r.sporteventCompetition.id === this.sporteventCompetition.id)
        .filter((r: SporteventResult) =>r.disciplineResults.find( dr => dr.usedAValue > 0) !== undefined)
    }

    this.onPropertyChanged(false);
    let rank = 0;
    this.currentResults.forEach((r: SporteventResult): void => {
      rank++;
      r.rank = rank;
      if (r.totalPoints > 0) {
        this.hide = false;
        return;
      }
      if(r.disciplineResults.find(dr => dr.usedAValue > 0) !== undefined){
        this.hide = false;
      }
    });
    this.onPropertyChanged(this.sortRankReverse);
    PrintSettingsService.setHeader();
    this.loading = false;
  }
}
